import React from 'react'

import './Style.css';
import './Media-styles.css';


import Blank from './img/Colours/Colour/blank.webp'
import Plain from './img/Colours/Colour/Plain.webp';
import Bridge from './img/Colours/Colour/Bridge.webp';






function Colours() {
    return (
        <>
         


            

            <div className='inner_container-colour'>
                
                <div className='inner-body-container-colour'>
                    <div className='inner_body-colour'>
                        
                    
                        <div className='colour-grid'>
                        
                            <div className='colour-col col-border'><img src={Plain} alt="Acoustics" /><div className='colur-name1'>Plain</div></div>
                            <div className='colour-col col-border'><img src={Bridge} alt="Acoustics" /><div className='colur-name1'>Bridge Perforated</div></div>
                            <div className='colour-col'><img src={Blank} alt="Acoustics" /></div>
                            <div className='colour-col'><img src={Blank} alt="Acoustics" /></div>
                            
                            <div className='colour-col'></div>
                        </div>
                       
                      
                 
               

               
              
                    </div>

                </div>
                <div className='note'></div>

            </div>

            
        </>

    )
}


export default Colours
