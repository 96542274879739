import React from 'react'

import './Style.css';
import './Media-styles.css';


//import Blank from './img/Colours/Colour/blank.webp'
import Surfmist from './img/Colours/Colour/Surfmist01.webp';
import Nightsky from './img/Colours/Colour/NightSky.webp';
import Timber from './img/Colours/Colour/Timber.webp';
import Powder from './img/Colours/Colour/Powder-coating.webp';






function Colours() {
    return (
        <>
         


            

            <div className='inner_container-colour'>
                
                <div className='inner-body-container-colour'>
                    <div className='inner_body-colour'>
                        
                    
                        <div className='colour-grid'>
                        
                            <div className='colour-col col-border'><img src={Surfmist} alt="Acoustics" /><div className='colur-name1'>Surfmist</div></div>
                            <div className='colour-col col-border'><img src={Nightsky} alt="Acoustics" /><div className='colur-name1'>Night Sky</div></div>
                            <div className='colour-col col-border'><img src={Timber} alt="Acoustics" /><div className='colur-name1'>Timber</div></div>
                            <div className='colour-col'><img src={Powder} alt="Acoustics" /><div className='colur-name1'>Powder coating</div></div>
                            
                            <div className='colour-col'></div>
                            
                        </div>
                       
                      
                       
               
                      <div className='pl_note'>
                        <h3>Given sufficient quantity, any finish from the extensive Colorbond range can be provided.</h3></div>
              
                    </div>

                </div>
                <div className='note'></div>

            </div>

            
        </>

    )
}


export default Colours
