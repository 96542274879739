import React from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';

import PaneProduct from './PaneProduct'


import 'react-accessible-accordion/dist/fancy-example.css';


import PanelineBanner from './img/Images/paneline-banner.webp';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';


import Icon1 from './img/Images/icon1.png';
import Icon2 from './img/Images/icon2.png';
import Icon3 from './img/Images/icon3.png';
import Icon4 from './img/Images/icon4.png';
import Icon5 from './img/Images/icon5.png';
import Icon6 from './img/Images/icon6.png';
import Icon7 from './img/Images/icon7.png';

function Product() {
  return (
    <>
    <Helmet>
    <title>Acoustics</title>
                <meta name="title" content="Content here" />
                <meta name="description" content="Content here" />
                <meta name="keywords" content="Content here" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>
      <Header />

 <div className='innerBanner'><img src={PanelineBanner} alt="Acoustic" /></div>
 <div className='welcome-container-product'>
 <div className='welcome-col-product'><div className='pro-copy-intro'><div className='main-body'><h1>Paneline</h1>

   <h3>The Paneline acoustic ceiling system is fully customizable to suit your project's specific needs, making it suitable for both internal and external applications. This weatherproof system boasts an impressive Noise Reduction Coefficient (NRC 0.95) value, making it an ideal choice for a wide range of acoustically sensitive applications.</h3>
   </div>
  </div>
  </div>



</div>
<div className='welcome-container2'><div className='main-body'>
<div className='welcome-col-pro1'><div className='welcome-copy'>
<h3><em className='emph'><img src={Icon1} alt="Tick" />Made in Western Australie</em></h3>
   <h3><em className='emph'><img src={Icon2} alt="Tick" />Deemed non-combustible</em></h3>
   <h3><em className='emph'><img src={Icon3} alt="Tick" />Acoustic rated</em></h3>
   <h3><em className='emph'><img src={Icon4} alt="Tick" />Design flexibility</em></h3>
   <h3><em className='emph'><img src={Icon5} alt="Tick" />Easy to install</em></h3>
   <h3><em className='emph'><img src={Icon6} alt="Tick" />Low maintaince</em></h3>
   <h3><em className='emph'><img src={Icon7} alt="Tick" />Light weight</em></h3>
  
</div>
</div>




<div className='welcome-col-pro1'>  <div className='acu-col-right-dnld'><h3>Paneline has been widely and successfully used in construction since the early 1980’s, gaining a reputation as a trustworthy building material celebrated for its exceptional acoustical qualities, design adaptability, strength and durability. </h3><ul>
<li className='resources'><a href="https://www.acoustic.com.au/downloads/Acoustics-Product-Brochure.pdf" target="_blank" rel="noreferrer">Product Brochure</a></li>
<li className='resources'><a href="http://acoustic.com.au/downloads/Acoustics-Installation-Guide.pdf" target="_blank" rel="noreferrer">Installation Guide</a></li>
<li className='resources'><a href="http://acoustic.com.au/downloads/Acoustics-Data-Sheet.pdf" target="_blank" rel="noreferrer">Data Sheet</a></li>
<li className='resources-testing'><a href="http://acoustic.com.au/downloads/Tests/Acoustics-Test-Certificate.pdf" target="_blank" rel="noreferrer">Acoustic Test Certificate</a></li>
<li className='resources-fire'><a href="http://acoustic.com.au/downloads/Tests/Acoustics-Fire-Test-Certificate.pdf"  target="_blank" rel="noreferrer">Fire Test Certificate</a></li>
<li className='resources-warranty'><a href="http://acoustic.com.au/downloads/Warranty/Acoustics-Warranty-Policy.pdf" target="_blank" rel="noreferrer">Warranty Policy</a></li></ul>
  </div></div>
  </div>
</div>
<div className='welcome-col-product'><PaneProduct /></div>

     
    

<Footer />
 
    </>
  )
}

export default Product









