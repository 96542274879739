import React from 'react'
import './PaneProduct.css'
import Surface from './Surface';
import Colours from './Colours';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

function AcTabs() {
    return (
        <div className='tab main-body'>
        <Accordion allowZeroExpanded>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                Product description
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>

            <div className='pro-copy'>
  <h3>
  Paneline panels manufactured 
  locally in Perth, Western Australia, Paneline provides a reliable 
  solution for a wide range of construction needs, with 
  panels measuring 82mm in width and a set 18mm gap (100mm 
  module).
    </h3><h3>
    Paneline, evaluated per AS 1397:2011, AS 1530.3, and 
AS 1580.3 standards, harnesses the inherent qualities of 
Colorbond steel. It boasts fire resistance and corrosion 
resistance. Paneline is incredibly versatile and can be 
customized to fulfill specific requirements for both exterior and 
interior applications
    </h3>
    <div className='list-title'>Substrate</div><ul>
    <li className='pro-list'>Zincalume Steel</li></ul>
    <div className='list-title'>Standard Finish</div><ul>
    <li className='pro-list'>Colorbond Surf Mist</li></ul>   
    <div className='list-title'>Common applications</div><ul>
    <li className='pro-list'>Interior acoustic ceiling systems</li>
    <li className='pro-list'>Soffits/ under covered ceilings</li></ul>
    <div className='list-title'>Panel lengths</div><ul>
    <li className='pro-list'>Paneline is available in standard panels measuring 82mm
    x 6m panels, but can be customized to fit specific project
    requirements, reducing on site labour and cutting to waste.</li></ul></div> 
                
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                Surface profiles
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <Surface />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
            Finish options
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>

        <div className='pro-copy'>
<Colours />
        </div> 
            
        </AccordionItemPanel>
    </AccordionItem>
    </Accordion>
          <div className='spacer'></div> 

        </div>
    )
}

export default AcTabs
